const Menu = {
  namespaced: true,
  state() {
    return {
      title: {
        fr: 'Notre Menu',
        en: 'Our Menu',
      },
      menu: {
        fr: [
          /* {
            id: 0,
            path: require('@/assets/menu finale_Plan 1.jpeg'),
          },*/
          {
            id: 1,
            path: require('@/assets/1-1.jpeg'),
          },
          {
            id: 2,
            path: require('@/assets/2-2.jpeg'),
          },
          {
            id: 3,
            path: require('@/assets/3-3.jpeg'),
          },
          {
            id: 4,
            path: require('@/assets/4-4.jpeg'),
          },
          {
            id: 5,
            path: require('@/assets/5-5.jpeg'),
          },
          {
            id: 6,
            path: require('@/assets/6-6.jpeg'),
          },
          {
            id: 7,
            path: require('@/assets/7-7.jpeg'),
          },
          {
            id: 8,
            path: require('@/assets/8-8.jpeg'),
          },
          {
            id: 9,
            path: require('@/assets/9-9.jpeg'),
          },
          {
            id: 10,
            path: require('@/assets/10-10.jpeg'),
          },
          {
            id: 11,
            path: require('@/assets/11-11.jpeg'),
          },
          {
            id: 12,
            path: require('@/assets/12-12.jpeg'),
          },
          {
            id: 13,
            path: require('@/assets/13-13.jpeg'),
          },
          {
            id: 14,
            path: require('@/assets/14-14.jpeg'),
          },
          {
            id: 15,
            path: require('@/assets/15-15.jpeg'),
          },
        ],
        en: [
          /* {
            id: 0,
            path: require('@/assets/menu finale_Plan 1.jpeg'),
          },*/
          {
            id: 1,
            path: require('@/assets/1-1.jpeg'),
          },
          {
            id: 2,
            path: require('@/assets/2-2.jpeg'),
          },
          {
            id: 3,
            path: require('@/assets/3-3.jpeg'),
          },
          {
            id: 4,
            path: require('@/assets/4-4.jpeg'),
          },
          {
            id: 5,
            path: require('@/assets/5-5.jpeg'),
          },
          {
            id: 6,
            path: require('@/assets/6-6.jpeg'),
          },
          {
            id: 7,
            path: require('@/assets/7-7.jpeg'),
          },
          {
            id: 8,
            path: require('@/assets/8-8.jpeg'),
          },
          {
            id: 9,
            path: require('@/assets/9-9.jpeg'),
          },
          {
            id: 10,
            path: require('@/assets/10-10.jpeg'),
          },
          {
            id: 11,
            path: require('@/assets/11-11.jpeg'),
          },
          {
            id: 12,
            path: require('@/assets/12-12.jpeg'),
          },
          {
            id: 13,
            path: require('@/assets/13-13.jpeg'),
          },
          {
            id: 14,
            path: require('@/assets/14-14.jpeg'),
          },
          {
            id: 15,
            path: require('@/assets/15-15.jpeg'),
          },
        ],
      },
    };
  },
  mutations: {
    setMenus(state, value) {
      state.prices = value;
    },
  },
  getters: {
    getmenu(state, getters, rootState, rootGetters) {
      return state.menu[rootGetters['lang/getCurrentLang']];
    },
    gettitle(state, getters, rootState, rootGetters) {
      return state.title[rootGetters['lang/getCurrentLang']];
    },
  },
};

export default Menu;
